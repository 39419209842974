$(document).ready(function () {

    // Close menu on clicking an item (only relevant for inline anchor links)

    $(".menu-item").on("click", function () {

        if ($("#head").hasClass('mobile-menu-open')) {

            $(".burger-icon").click();
        }

    });

    $(".burger-icon").click(function () {

        $(".header-social-links-wrapper").slideUp(150);

        let menuItems = document.querySelectorAll("header .menu-item");
        let frameCover = document.querySelector(".menu");

        if (!$("#head").hasClass('mobile-menu-open')) {

            $(frameCover).css("display", "flex");
            $(frameCover).fadeTo(250, 1, function () {
                TweenMax.staggerTo(menuItems, 0.25, {
                    opacity: 1,
                    y: 0,
                }, 0.25 / menuItems.length);
            })

        } else {

            TweenMax.to(frameCover, 0.15, {
                opacity: 0,
                onComplete: function () {
                    TweenMax.set("header .menu-item", {
                        opacity: 0,
                        y: -10
                    });
                    $(frameCover).css("display", "none");
                }
            });
        }

        $("#head").toggleClass("mobile-menu-open");
        $("html").toggleClass("mobile-menu-open");

    });

    $("#header-social-links-toggle").on("click", function () {

        let socialWrapper = document.querySelector(".header-social-links-wrapper");

        if ($("#head").hasClass("mobile-menu-open")) {

            $(".burger-icon").click();

        }

        if (socialWrapper.style.display !== "block") {

            $(socialWrapper).slideDown(150);

        } else {

            $(socialWrapper).slideUp(150);

        }

    });

})