import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

const environment = ENV_NAME || 'local';

Sentry.init({
  dsn: "https://2852dea0c0944e508a35489275444baf@o436801.ingest.sentry.io/6049085",
  allowUrls: ["inthehiddencity.com", "hid.city"],
  integrations: [new Integrations.BrowserTracing()],
  environment: environment,
  enabled: !['local', 'standalone', 'test'].includes(environment),
  ignoreErrors: SENTRY_IGNORE,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

var $ = require('jquery');

window.jQuery = $;
window.$ = $;

import "./javascript/tooltips.js";
import './javascript/general.js'
import './javascript/landing.js'
import './javascript/stripe.js'

import './javascript/effectOnScroll.js'
import './javascript/TweenMax.min.js'
import './javascript/staggerMenu.js'
import './javascript/stickyHeader.js'


// Stylesheets

import './styles/reset.css'
import './styles/v2.css'
import './styles/fonts.css'
import './styles/header.scss'
import './styles/buttons.css'
import './styles/lists.scss'
import './styles/layouts.scss'
import './styles/footer.css'

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import './styles/stripe.css'
import './styles/tables.css'
import './styles/calendar.css'

// Fancybox, for popups

const fancybox = require('@fancyapps/fancybox');
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';
