import Plyr from "plyr";
import styles from "plyr/dist/plyr.css";

window.scrollTo = function (selector, offset = 70) {
  if (selector && document.querySelector(selector)) {
    window.setTimeout(function () {
      if (!$(selector.offset) || !$(selector).offset()) {
        return;
      }

      let scrollOffset = $(selector).offset().top - offset;

      $([document.documentElement, document.body]).scrollTop(scrollOffset);
    }, 100);
  }
};

window.getURLParams = function () {
  var url = document.location.href;

  let vars = {},
    hash;
  let hashes = url.slice(window.location.href.indexOf("?") + 1).split("&");
  for (let i = 0; i < hashes.length; i++) {
    hash = hashes[i].split("=");
    vars[hash[0]] = hash[1];
  }
  return vars;
};

// Wrapper for Google Analytics events.

window.trackEvent = function (category, action, label, value) {
  try {
    gtag("event", label, {
      event_category: category,
      event_label: label,
      event_action: action,
      event_value: value,
    });
  } catch (e) {
    // TODO https://trello.com/c/vidooIFF/712-sentry-for-front-end - Raise with Sentry when added
  }
};

window.trackEventObject = function (label, params) {
  try {
    gtag("event", label, params);
  } catch (e) {}
};

// Track any scroll event

window.scrolled = false;
window.scrolled15 = false;

$(window).on("scroll", function () {
  if (!window.scrolled) {
    window.trackEvent("Browse", "Scroll", "Any scroll");

    window.scrolled = true;
  }

  if (window.scrollY > window.innerHeight * 0.15 && !window.scrolled15) {
    window.trackEvent("Browse", "Scroll", "Scrolled 15% of screen height");

    window.scrolled15 = true;
  }

  if (window.scrollY > window.innerHeight && !window.interacted) {
    if (window.fbq) {
      fbq("trackCustom", "engagement-action");
    }

    window.interacted = true;
  }
});

$("body").on("click", function () {
  if (!window.interacted) {
    if (window.fbq) {
      fbq("trackCustom", "engagement-action");
    }

    window.interacted = true;
  }
});

// Social link google events

$("body").on("click", "[data-social]", function () {
  let type = this.getAttribute("data-social").toLowerCase();
  let location = this.getAttribute("data-social-position");
  let destination = this.getAttribute("href");

  if (type === "twitter") {
    type = "x";
  }

  window.trackEventObject("Share icon click", {
    event_category: "Browse",
    destination_url: destination,
    asset_name: "share_icon_" + type,
    asset_location: location,
  });
});

$(document).ready(function () {
  const regex = new RegExp(`(^| )LOGGED_IN_USER_ID=([^;]+)`);
  const match = document.cookie.match(regex);
  const signedInGameId = match ? match[2] : null;

  if (signedInGameId) {
    $("#signed-out-link--header").hide();
    $("#signed-in-link--header").show();
    $("#signed-out-link--burger").hide();
    $("#signed-in-link--burger").show();
    $("#signed-in-text--header").html("Game " + signedInGameId);
    $("#signed-in-text--burger").html("Game " + signedInGameId);
  } else {
    $("#signed-out-link--header").show();
    $("#signed-in-link--header").hide();
    $("#signed-out-link--burger").show();
    $("#signed-in-link--burger").hide();
  }

  // Make whole game card a link to explore if it exists, and book if not. But let booking button go to booking page if card goes to explore.

  $(".clickable-card").on("click", function (e) {
    // If two buttons, ignore this

    if (
      $(this).closest(".clickable-card").find("a.specific-booking-link")
        .length > 1
    ) {
      return;
    }

    window.location = $(this)
      .closest(".clickable-card")
      .find("a.specific-booking-link")
      .attr("href");
  });

  // Social links tracking events
  // Keeping old "AddToAny" names but these should probably be called "social" or something as they're not all AddToAny anymore.

  $("header").on("click", "[data-social]", function () {
    let channel = this.getAttribute("data-social");

    let url = document.location.href;

    trackEvent("AddToAny", channel, url);
  });

  // Detect ios and add body class

  let isiOS = function () {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };

  if (isiOS()) {
    $("html").attr("data-ios", true);
  }

  $("a.smooth").on("click", function (event) {
    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();

      // Store hash
      var hash = this.hash;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $("html, body").animate(
        {
          scrollTop: $(hash).offset().top - 50,
        },
        800,
        function () {
          // Add hash (#) to URL when done scrolling (default click behavior)
          // window.location.hash = hash;
        }
      );
    } // End if
  });

  // How it works video actions

  $(".how-it-works-video").on("pause", function () {
    trackEvent(
      "Video",
      "How it works video paused",
      document.location.href,
      this.currentTime
    );
  });

  $(".how-it-works-video").on("play", function () {
    trackEvent("Video", "How it works video played", document.location.href);
  });

  // Replace HTML5 How it Works video with Plyr for greater customisation

  if ($(".main-how-it-works-video").length) {
    const player = new Plyr(".main-how-it-works-video");

    player.on("ready", function () {
      window.registerFirstScrollTo(
        ".how-it-works-video__wrapper .plyr__control--overlaid",
        function () {
          // Don't run this on how it works page

          let route = document.querySelector("html").getAttribute("data-route");

          if (route === "how-it-works") {
            return;
          }

          window.trackEvent(
            "Browse",
            "Scroll",
            "Scrolled to 50% of How it Works video visible"
          );
        },
        1
      );
    });

    player.on("click", function () {
      player.toggleControls();
    });

    player.on("touchstart", function () {
      player.toggleControls();
    });
  }

  // Replace HTML5 hunt full trailer video with Plyr for greater customisation

  if ($(".hunt-full-video").length) {
    const player = new Plyr(".hunt-full-video");

    player.on("ready", function () {
      window.registerFirstScrollTo(
        ".hunt-full-video__wrapper .plyr__control--overlaid",
        function () {
          window.trackEvent(
            "Browse",
            "Scroll",
            "Scrolled to 50% of trailer video visible"
          );
        },
        1
      );
    });

    player.on("play", function () {
      let game = $(".landing-page").attr("data-game-shortcode");

      window.trackEvent("Video", "Play Trailer", game);
    });

    player.on("click", function () {
      player.toggleControls();
    });

    player.on("touchstart", function () {
      player.toggleControls();
    });
  }

  // Leaderboard interactivity

  $("body").on("click", ".col0", function () {
    $(this).toggleClass("open");
  });

  $("#detailed-results-picker").on("change", function () {
    let index = $(this).val();

    $(".detailed-results-table").removeAttr("data-active");

    $("[data-index=" + index + "]").attr("data-active", true);
  });

  // Hide floating buttons if another button on page

  if (document.getElementById("floating-button")) {
    // Ignore if IntersectionObserver is not supported

    if (!window.IntersectionObserver) {
      return false;
    }

    let options = {};

    if (window.innerWidth > 900) {
      options.threshold = [0.5, 0.25];
    } else {
      options.threshold = [0.15, 0.1];
    }

    let observer = new IntersectionObserver(function (entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $("#floating-button").hide();
        } else {
          $("#floating-button").show();
        }
      });
    }, options);

    if (document.querySelector(".system-form")) {
      observer.observe(document.querySelector(".system-form"));
    }

    if (document.querySelector("#games")) {
      observer.observe(document.querySelector("#games"));
    }
  }

  $(window).on("scroll", function () {
    // Hide banner if scrolled 15% on mobile

    if (
      $(".alert-banner").length &&
      window.scrollY > window.innerHeight * 0.15 &&
      window.innerWidth < 850
    ) {
      $(".alert-banner").remove();
      $("header.has-banner").css("top", 0);
      $("body").css("top", 0);
    }
  });

  let alertBannerResize = function () {
    // If there's an alert banner, adjust the top menu sizing offset to fit it
    if ($(".alert-banner").length) {
      let height = $(".alert-banner").height();

      $("header.has-banner").css("top", height + "px");
      $("body").css("top", height + "px");
      $("body").css("position", "relative");
      $(".alert-banner").css("visibility", "visible");
    }
  };

  $(window).on("resize", alertBannerResize);

  alertBannerResize();

  $(".alert-banner .button-close").on("click", function () {
    $(".alert-banner").remove();
    $("header.has-banner").css("top", 0);
    $("body").css("top", 0);
  });

  // Register first scroll to banner

  window.registerFirstScrollTo(".footer-menu", function () {
    window.trackEvent("Browse", "Scroll", "scrolled to footer logo visible");
  });
});

window.gMapLoad = function (url) {
  if (
    /* if we're on iOS, open in Apple Maps */
    navigator.platform.indexOf("iPhone") != -1 ||
    navigator.platform.indexOf("iPad") != -1 ||
    navigator.platform.indexOf("iPod") != -1
  ) {
    url = url.replace("https", "maps");
  }

  window.open(url);
};

// Helper function for tracking scroll to a specific position (first time)

window.scrolledTo = {}; // Database of scroll events already triggered

window.registerFirstScrollTo = function (selector, callback, threshold = 0) {
  // Ignore if not supported

  if (!window.IntersectionObserver) {
    return false;
  }

  if (!document.querySelector(selector)) {
    return false;
  }

  let internalCallback = function (entries) {
    if (window.scrolledTo[selector]) {
      return false;
    }

    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        callback();
        window.scrolledTo[selector] = true;
      }
    });
  };

  let options = {};

  // Get selector height and offset percent if needed

  options.threshold = threshold;

  let observer = new IntersectionObserver(internalCallback, options);

  if (document.querySelector(selector)) {
    observer.observe(document.querySelector(selector));
  }
};

// Global HiddenCity JavaScript class.

class HC {
  loadAllLeaderBoards(selector) {
    $.get("/api/leaderboards/all", function (data) {
      $(selector).html(data);
    });
  }

  loadLeaderBoard(selector, slug) {
    $.get("/api/leaderboards/" + slug, function (data) {
      $(selector).html(data);
    });
  }
}

window.hc = new HC();

$(() => {
  let slideshow = document.querySelector(".hc-slideshow");
  if (slideshow) {
    let horses = Array.from(
      document.querySelectorAll(".hc-slideshow .slideshow-img")
    );

    let currenthorse = 0;

    let cycle = () => {
      horses.forEach((e) => {
        e.style.opacity = 0;
      });

      if (currenthorse >= horses.length) {
        currenthorse = 0;
      }

      horses[currenthorse].style.opacity = 1;

      currenthorse += 1;
    };

    new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (window.slideshowStarted) {
              return;
            }

            window.slideshowStarted = true;

            // Cycle the first time without pause

            cycle();

            window.setInterval(cycle, 3000);
          }
        });
      },
      {
        threshold: 0.5,
      }
    ).observe(slideshow);
  }
});

window.experienceMore = function () {
  let visible = $("#experience-more").is(":visible");

  if (visible) {
    $("#experience-more").hide();
    $("#experience-more-push").hide();

    $("#experience-more-button").show();

    $("#experience-more-button").parent().css("visibility", "visible");
  } else {
    $("#experience-more-button").hide();

    // Hide the paragraph the read more button is in

    $("#experience-more-button").parent().css("visibility", "hidden");

    $("#experience-more").show();
    $("#experience-more-push").show();

    let size = $("#experience-more").height() - 56;

    $("#experience-more-push").height(size);
  }
};
