// A version of the Stripe integration for voucher payments.
// This is split off because it uses the new stripe api and the workflow is different. Eventually both systems should be merged to use the new Stripe API.

import * as EmailValidator from "email-validator";

window.buyingVoucher = false;

$("body").on("focus", ".has-message input", function () {
  $(this).closest(".has-message").removeClass("has-message");
});

window.showError = (
  message = "Something went wrong please check your details",
  element = null
) => {
  if (element) {
    window.showMessageInField(element, message, "error");
  } else {
    // Generic error popup
    window.popup("Error", message);
  }
  // Now reset
  window.buyingVoucher = false;
  $("#hummingbird_static").removeClass("run-ticker");
};

window.paymentRequestButtons = async () => {
  let stripe = Stripe($("#console_static").attr("data-stripe-key"));

  const amount = parseInt(document.getElementById("voucher-price").value) * 100;

  if (!amount) {
    return;
  }

  const paymentRequest = stripe.paymentRequest({
    country: "GB",
    currency: "gbp",
    total: {
      label: "Voucher total",
      amount: amount,
    },
    disableWallets: ["link", "browserCard"],
    requestPayerName: true,
    requestPayerEmail: true,
  });

  // Make a payment request button using data attributes in the console
  const elements = stripe.elements();
  const prButton = elements.create("paymentRequestButton", {
    paymentRequest,
    style: {
      paymentRequestButton: {
        type: "default",
        theme: "light",
        height: "44px",
      },
    },
  });

  // Check if the user's browser supports Apple Pay / Google Pay etc via the web payments API
  const supportsWebPaymentAPI = await paymentRequest.canMakePayment();

  if (!supportsWebPaymentAPI) {
    return;
  }

  // Check which button shown and Google Event it

  if (supportsWebPaymentAPI.applePay) {
    window.trackEvent("Booking", "Show Apple Pay option", "Apple Pay shown");
  }

  if (supportsWebPaymentAPI.googlePay) {
    window.trackEvent("Booking", "Show Google Pay option", "Google Pay shown");
  }

  document.getElementById("checkout-buttons").style.display = "block";

  prButton.mount("#checkout-buttons");

  prButton.on("click", function (event) {
    if (supportsWebPaymentAPI.applePay) {
      window.trackEvent(
        "Booking",
        "Click 'Book with Apple Pay'",
        "Apple Pay selected"
      );
    }

    if (supportsWebPaymentAPI.googlePay) {
      window.trackEvent(
        "Booking",
        "Click 'Book with GPay'",
        "Google Pay selected"
      );
    }
  });

  // Get the client secret for making the payment from the server

  paymentRequest.on("paymentmethod", async (ev) => {
    // Someone has already entered their google pay details

    // Call controller to get firstname lastname from name

    let firstName = ev.payerName.split(" ")[0];
    let lastName = ev.payerName.split(" ")[1];
    let email = ev.payerEmail;

    let clientSecret = await getPaymentIntentCardorKlarna(
      email,
      firstName,
      lastName,
      false,
      amount
    );

    // Confirm the PaymentIntent without handling potential next actions (yet).
    const { paymentIntent, error: confirmError } =
      await stripe.confirmCardPayment(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      );

    if (confirmError) {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      ev.complete("fail");
      window.showError(confirmError.message);
    } else {
      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.
      ev.complete("success");
      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow. If using an API version older than "2019-02-11"
      // instead check for: `paymentIntent.status === "requires_source_action"`.
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const { error } = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          // The payment failed -- ask your customer for a new payment method.
          window.showError(error.message);
        } else {
          // Success

          voucherSuccess();
        }
      } else {
        // Success

        voucherSuccess();
      }
    }
  });
};

window.setVoucherPrice = () => {
  let price = document.getElementById("voucher-price").value;

  window.paymentRequestButtons();

  $("#voucher-price-display").text("£" + price);
};

// Load the payment request buttons on load
$(async () => {
  if (document.getElementById("checkout-buttons")) {
    paymentRequestButtons();
  }
});

window.getPaymentIntentCardorKlarna = (
  email,
  firstName,
  lastName,
  klarna = false,
  amount
) => {
  amount = parseInt(amount);

  let design = document.getElementById("voucher-design").value;

  if (isNaN(amount) || amount < 1) {
    return false;
  }

  return new Promise((resolve, reject) => {
    $.post(
      "/payment/intent/buy-voucher-card",
      { email, firstName, lastName, klarna, amount, design },
      (intent) => {
        resolve(intent);
      }
    );
  });
};

window.voucherPaymentCardSetup = async (e) => {
  if (e) {
    e.preventDefault();
  }

  let stripe = Stripe($("#console_static").attr("data-stripe-key"));

  let elements;

  elements = stripe.elements();

  const paymentElement = elements.create("card", {
    billing_details: {
      email: email,
      address: {
        country: "GB",
      },
    },
  });

  paymentElement.mount("#card-element");

  window.voucherPaymentCard = async (e, klarna = false) => {
    if (window.buyingVoucher) {
      return false;
    }

    $("#hummingbird_static").addClass("run-ticker");

    window.buyingVoucher = true;

    e.preventDefault();

    let email = document.getElementById("email").value;
    let firstName = document.getElementById("firstName").value;
    let lastName = document.getElementById("lastName").value;
    let amount =
      parseFloat(document.querySelector(".gift-voucher-value").value) * 100;

    if (!EmailValidator.validate(email)) {
      window.showError("Invalid email", "email");

      return;
    }

    if (!firstName) {
      window.showError("Enter a first name", "firstName");

      return;
    }

    if (!lastName) {
      window.showError("Enter a last name", "lastName");

      return;
    }

    if (!amount) {
      window.showError("Voucher amount needs to be a positive number");
      return;
    }

    // Now we need to get a client secret

    let clientSecret = await getPaymentIntentCardorKlarna(
      email,
      firstName,
      lastName,
      klarna,
      amount
    );

    // If Klarna, we go down

    if (klarna) {
      // Redirects away from the client
      const { error } = await stripe.confirmKlarnaPayment(clientSecret, {
        payment_method: {
          billing_details: {
            email: email,
            address: {
              country: "GB",
            },
          },
        },
        return_url:
          location.protocol +
          "//" +
          location.host +
          "/payment/klarna/callback?product_type=voucher&origin=" + location.pathname,
      });

      if (error) {
        showError(result.error.message);
      }

      return;
    }

    stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: paymentElement,
        },
      })
      .then(function (result) {
        if (result.error) {
          // Show error to your customer
          showError(result.error.message);
        } else {
          // The payment succeeded!
          voucherSuccess(result.paymentIntent.id);
        }
      });
  };
};

window.voucherSuccess = (id) => {
  $.post("/payment/voucher-finished", function (data) {
    window.parent.location.href = data.returnUrl;
  }).fail(function () {
    alert(
      "Something went wrong processing your payment. Please contact support."
    );
  });
};
