// Start timer for GA events

window.loadTimer = Date.now();
window.loadTimeSent = false;

// Put in timer to load fallback image
// if video hasn't loaded in time

window.slowVideoTimer = window.setTimeout(function () {

    $("[data-has-video]").removeAttr("data-has-video");

    let timer = Date.now() - window.loadTimer;

    window.trackEvent("Performance", "Usable content displayed", "Still image", Math.round(timer / 1000));

}, 5000);

window.videoLoaded = function () {

    window.clearTimeout(window.slowVideoTimer);

    let timer = Date.now() - window.loadTimer;

    window.trackEvent("Performance", "Usable content displayed", "Video", Math.round(timer / 1000));

    let video = document.querySelector(".trailer-video");
    video.setAttribute("data-loaded", true);

    // Put in relevant poster image

    if (window.innerWidth > window.innerHeight) {

        $(video).attr("poster", $(video).attr("data-poster-landscape"));

    }

    if (window.innerWidth < window.innerHeight) {

        $(video).attr("poster", $(video).attr("data-poster-portrait"));

    }

    // Add video duration data attribute
    $(video).on("timeupdate", function () {

        if (!$(video).attr("data-length")) {

            $(video).attr("data-length", video.duration);

        }

    })

}

window.getVideo = function () {

    return document.querySelector(".trailer-video");

}

$(document).ready(function () {

    if($(".trailer-sound").length) {

        let trailer_sound = document.querySelector(".trailer-sound");
        let original_wrapper = document.querySelector(".trailer-sound-original-wrapper");

        window.setInterval(() => {

            let hummingbird;
                 
            if(document.getElementById("hummingbird_static")){

                hummingbird = document.querySelector("#hummingbird_static");

            }

            if(document.getElementById("hummingbird")){

                hummingbird = document.querySelector("#hummingbird");

            }

            if(hummingbird){

                let hummingbird_height = hummingbird.clientHeight; 

                if(hummingbird_height){

                    let offset = hummingbird_height - 58;

                    document.querySelector(".hero-visible-scroll-marker").style.top = offset + "px";

                }

            }



        }, 500)

        let observer = new IntersectionObserver(function (entries) {

            entries.forEach(entry => {

                if (entry.isIntersecting) {

                    // Should be in original position

                    original_wrapper.prepend(trailer_sound);
                    
                } else {

                    let hummingbird;
                 
                    if(document.getElementById("hummingbird_static")){

                        hummingbird = document.querySelector("#hummingbird_static");

                    }

                    if(document.getElementById("hummingbird")){

                        hummingbird = document.querySelector("#hummingbird");

                    }

                    // Should be docked to hummingbird
                    
                    hummingbird.prepend(trailer_sound);

                }
                
            });

        }, {});

        observer.observe(document.querySelector(".hero-visible-scroll-marker"));

        $(".trailer-sound").on("click", function(e) {
            e.stopImmediatePropagation();
            const video = document.querySelector(".trailer-video");
            if (video.muted) {
                video.muted = false;
                $(this).removeClass("trailer-sound--mute");
                $(this).addClass("trailer-sound--unmute");
            } else {
                video.muted = true;
                $(this).removeClass("trailer-sound--unmute");
                $(this).addClass("trailer-sound--mute");
            }
        })
        
        let muteTimeout;
        
        let flashMute = function() {
            $(".trailer-sound").css("opacity", 1);
            clearTimeout(muteTimeout);
            
            muteTimeout = setTimeout(function() {
                $(".trailer-sound").css("opacity", 0);
            }, 2000);
        }
        
        window.addEventListener("pointermove", flashMute);
        window.addEventListener("scroll", flashMute);
    }
    
    $("section.touchzone").on("click", function (e, noTrack) {

        if (getVideo()) {

            if (getVideo().paused) {
                getVideo().play();
                window.trackEvent("Video", "Played background video", document.location.href);
            } else {
                getVideo().pause();
                window.trackEvent("Video", "Paused background video", document.location.href);
            }

        }
    })

    if (window.scrollY > window.innerHeight) {

        document.body.setAttribute("data-scrolledVH", "true");

    }

    window.addEventListener('scroll', function (e) {

        // Only do this if a video is set

        if (document.querySelector(".trailer-video")) {

            let vidPosition = (window.pageYOffset || document.scrollTop) - (document.clientTop || 0);

            // Pause / restart video on scroll

            if (vidPosition > (window.innerHeight / 2)) {

                window.getVideo().pause();

                window.scrollPause = true;

            } else {

                if (window.scrollPause) {

                    window.getVideo().play();

                }

            }

            if (vidPosition > window.innerHeight) {

                document.body.setAttribute("data-scrolledVH", "true");

            } else {

                document.body.removeAttribute("data-scrolledVH");

            }

        }

    })

    // TODO: This can be deleted once the corporate and play at home pages are updated

    $("[data-fancybox]").fancybox({
        afterShow: function () {

            getVideo().pause();

            $("[data-play]").show();
            $("[data-pause]").hide();

        }
    });
    
    // Load scroll observer for reviews

    window.registerFirstScrollTo("#reviews_widgets", function () {

        window.trackEvent("Browse", "Scroll", "Reviews");

    });

    window.registerFirstScrollTo("#how-hiddencity-games-work", function () {

        window.trackEvent("Browse", "Scroll", "How HiddenCity Games Work");

    });

    $(".full-video-link-wrap").on("click", function () {

        let game = $(".landing-page").attr("data-game-shortcode");

        window.trackEvent("Video", "Play Trailer", game);

    })

    $("#glowing-chevron-scroll").on("click", function () {

        document.getElementById('experience-section').scrollIntoView();

    });

    $("#learnMoreButton").on("click", function () {

        document.getElementById('experience-section').scrollIntoView();

    });

});
