let triggerScrolledToEvents = function(){

    Array.from(document.querySelectorAll("[data-scroll-event]")).filter(function (el) {

        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        var elemBottom = rect.bottom;
    
        // Only completely visible elements return true:
    
        var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
    
        // Partially visible elements return true:
        isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    
        if(isVisible){

            $(el).trigger("visible");

        } else {

            $(el).trigger("notVisible");

        }
    
    });

};


// Run once on load and then on scroll

window.addEventListener('scroll', triggerScrolledToEvents);
