window.addEventListener('scroll', function (e) {

    let top = (window.pageYOffset || document.scrollTop) - (document.clientTop || 0);

    if (top > 50) {

        document.body.setAttribute("data-sticky", "true");

    } else {

        document.body.removeAttribute("data-sticky");

    }

});
