import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling
import {delegate} from 'tippy.js';

delegate('body', {
  target: '[data-tooltip]',
  content: (elem) => {

    return elem.getAttribute("data-tooltip");

  },
  trigger: 'mouseenter click',
});

