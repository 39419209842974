import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';

$(async () => {

    // If on gift page, hummingbird is always static version

    if(window.location.href.indexOf("gift") !== -1){

        $("#hummingbird_static").appendTo("body").show();
        return;

    }
    
    // On load, check if the hummingbird version should be live not lite
    // This is a check based on if a session exists or if the user is currently signed in
    
    let response = await fetch("/api/check-hummingbird-session");
    let session_info = await response.json();
   
    // Session info can either return a logged in hunt, an active hummingbird session or false for nothing.

    
    if(session_info.hummingbird){
        
        window.hummingbird();
        return;
    };

    let urlParams = window.getURLParams();

    if(urlParams["internal-referer"] === "select-game"){

        if ('URLSearchParams' in window) {
            const url = new URL(window.location)
            url.searchParams.delete("internal-referer")
            history.pushState(null, '', url);
        }

        window.hummingbird(true);
        return;

    }

    // If we have arrived on a game landing page from the select game screen, automatically go into hummingbird mode
    
    $("#hummingbird_static").appendTo("body").show();
    
    if(session_info.logged_in){
        
            $("#console_static").html(`<div>
            <h2 class="console-title font-21 bodyBold">Game in progress</h2>
            <div class="white-base black-text font-21 bodyBold">
                    <p class="width-55__pad-bottom text-center horizontal-margin-auto vertical-margin-none">You are signed in to <a href='/your-hunt'>game ${session_info.logged_in}</a>. <a href="/logout">Log out</a> if you wish to purchase another game.</p>
            </div>
        </div>`)
                
    }
  
    return;

})

window.hummingbird = async (fromStatic = false) => {

    // Initialise console, passing in whether it's a new console from the static version
    window.hummingbird = new hummingbird(fromStatic);
 
    // Hide Hummingbird lite

    $("#hummingbird_static").hide();
    

    // Track if going from static

    if(fromStatic){

        trackEvent("Booking", "Clicked Go", "Go");

        if (window.fbq) {
            fbq('trackCustom', 'HB_CHECKOUT_LOAD');
        } else {

            window.parent.postMessage({
                "event": "clicked-go"
            }, '*');

        }

    }

}

class hummingbird {

    constructor(fromStatic = false) {

        let self = this;

        self.refeshes = 0;

        // Store initial query string params

        self.URLParams = window.getURLParams();

        // List of full screen mobile states (used to stop rest of page scrolling behind)

        self.fullScreenMobileScreens = ["details", "choose-date", "choose-time", "booker-details"];

        // Toggle for whether the user has dismissed the switch game dialog
        self.dismissedSwitchGame = null;

        let selector;

        // Put hummingbird inside a wrapper if there's a wrapper for it

        if(document.querySelector("#hummingbird-wrapper")){

            selector = "#hummingbird-wrapper";

        } else {

            selector = "body";

        }

        $(selector).append('<div id="hummingbird"></div>');

        // Allow a gameID to be preloaded into the console on game landing pages
        // This variable is exposed on a landing page through a data attribute

        if ($(".landing-page").attr("data-game-id")) {

            self.landingPage = $(".landing-page").attr("data-game-id");
            self.landingPageSlug = $(".landing-page").attr("data-game-slug");

        }

        // Allow a gameID to be preloaded into the console
        // via a query string (useful for the embed version)

        if (self.URLParams.landingPage && self.URLParams.landingPageSlug) {

            self.landingPage = self.URLParams.landingPage;
            self.landingPageSlug = self.URLParams.landingPageSlug;

        }

        // Allow page to set specific currency via a data-attribute. On game landing pages there is a currency variable in the data yml file.

        self.currency = "GBP";

        if ($("[data-currency]").length) {

            self.currency = $("[data-currency]").attr("data-currency");

        }

        self.init(fromStatic);

    }

    async init(fromStatic = null){

        let self = this;

        await self.loadSessionInfo();

        // If player count is set set the players and go straight to the details form
        
        if(fromStatic){

            let playerCount = parseInt($(".player-count-current").val());

            if(document.location.href.indexOf("gift") !== -1){

                await self.selectGame(41);

            }

            await self.selectGame(self.landingPage);

            self.voucherInQuery = null;

            $.post("/api/hummingbird/setplayers", { players: playerCount }).done(async function (data) {
                
                // Check if voucher provided in query string
                await self.checkVoucherinQuery();

                await self.refreshConsole();

                if (self.voucherInQuery === true) {
                    $("body").addClass("voucher-just-added");
                }

            });

             return;

        }

        // Check if voucher provided in query string

        self.voucherInQuery = null;

        await self.checkVoucherinQuery();

        // Refresh console and minimise by default if in details view

        await self.refreshConsole();

        if (self.voucherInQuery === true) {
            $("body").addClass("voucher-just-added");
        }

        
        self.checkAddPlayersButtonState();

        if (!$("#console").attr("data-dismissed-switch-game")) {

            if(!self.session.progressedToBookerDetails) {
                self.toggleMinimised(true);
            }

        }

    }

    isMobile() {

        return window.innerWidth < 900;

    }

    popup(heading, message, button = true, options = {}, callback = () => false) {
        
        window.popup(heading, message, button, options, callback);

    }

    checkVoucherinQuery() {

        let self = this;

        return new Promise(function (resolve) {

            // Set voucher if the query string contains a voucher code

            let params = window.getURLParams();

            if (params.voucher) {

                self.voucherInQuery = true;

                self.validateVoucher(params.voucher).then(resolve).catch(function (xhr) {

                    // Don't show the voucher error message if
                    // setting via query string 

                    let message = JSON.parse(xhr.responseText);

                    self.popup("Voucher error", message.message);

                    resolve();

                });

            } else {

                resolve();

            }

        })

    }

    validateVoucherInline(selector) {

        let self = this;

        let code = $(selector).val();

        $(".voucher-block").removeClass("voucher-validated");

        self.clearValidationMessages(".voucher-code");

        $(".voucher-block").removeClass("voucher-validated");

        self.validateVoucher(code).then(function () {

            self.refreshConsole();

        }).catch(function (xhr) {

            let message = JSON.parse(xhr.responseText);

            self.showMessageInField("voucherCode", message.message, "error");

        })

    }

    clearValidationMessages(selector) {

        $(selector).removeClass("has-message");
        $(selector).removeClass("message-long");
        $(selector).removeClass(".error");
        $(selector).removeClass(".good");

    }

    showMessageInField(field, message, type, extraoffset = 0) {
        window.showMessageInField(field, message, type, extraoffset);
    }

    showCalendar() {

        let self = this;

        let hasVariablePricing = document.querySelectorAll("[data-variable-pricing]").length;
        
        self.fetchDatesFromApi().then(function (dates) {

            // Destroy calendar if already exists

            if (self.calendar && self.calendar.destroy) {

                self.calendar.destroy();

            }

            // Get first date so we can start the calendar at it

            let first_available = Object.keys(dates)[0];

            let priceList = {};

            // Get price list from all dates received

            Object.keys(dates).forEach((date) => {

                let slots = dates[date];

                slots.forEach(slot => {

                    if(!priceList[slot.state] || priceList[slot.state].unitCostGbp > slot.unitCostGbp){

                        priceList[slot.state] = slot;

                    }

                })

            })

            // Turn into an array so we can sort it
            priceList = Object.values(priceList);

            priceList.sort((a, b) => {

                return a.unitCostGbp - b.unitCostGbp; 

            })

            self.calendar = new Calendar(document.getElementById('calendar'), {
                plugins: [dayGridPlugin],
                firstDay: 1,
                columnHeaderText: function (date) {

                    let days = [
                        "S",
                        "M",
                        "T",
                        "W",
                        "T",
                        "F",
                        "S"
                    ];

                    return days[date.getDay()];

                },
                header: {
                    left: 'prev',
                    center: 'title',
                    right: 'next'
                },
                height: "auto",
                datesRender: function (date) {

                    // Set price list legend

                    $("#calendarPriceKey").html("");

                    priceList.forEach((item) => {
                        
                        $("#calendarPriceKey").append(`<span><div class='circle circle-available' style='background:${item.stateColour}'></div>${hasVariablePricing ? "From" : "At"} £${item.unitCostGbp} per player</span>`);
        
                    })
        
                    $("#calendarPriceKey").append(`<span class='not-available'><div class='circle circle-unavailable'></div>Not available</span>`);
                            

                    let currentMonth = date.view.currentStart.getMonth() + 1;

                    // Get dates for current month

                    let currentMonthDates = Object.keys(dates).filter(function (date) {

                        let month = parseInt(date.split("-")[1]);

                        return month === currentMonth;

                    });

                    let currentMonthSlots = currentMonthDates.map(function (date) {

                        return dates[date];

                    });

                    // Check if any of the currentMonthSlots have a date in the evening

                    let eveningSlots = currentMonthSlots.some(function (slots) {

                        let evening = slots.filter(function (slot) {

                            let startHour = slot.startUntil.split(":")[0];

                            return startHour > 18 && slot.spaces;

                        });

                        return evening.length > 0;

                    });

                    if (eveningSlots) {

                        $(".date-filters-wrapper").show();

                    } else {

                        $(".date-filters-wrapper").hide();

                    }

                    // Loop over available dates and change colours
                    
                    Object.keys(dates).forEach(function (date) {

                        let availability = "green";

                        let totalSpaces = 0;

                        let backgroundColour = "";

                        // Sort by lowest cost

                        dates[date].sort((a, b) => {

                            return a.unitCostGbp - b.unitCostGbp

                        });
                        
                        
                        dates[date] = dates[date].filter((s) => s.spaces);

                        // If no slots return early

                        if(!dates[date].length){

                            return;

                        }

                        let cheapestSlot = dates[date][0];

                        backgroundColour = cheapestSlot.stateColour;

                        dates[date].forEach(function (element) {
                            
                            totalSpaces += element.spaces;
                            
                        });
                        
                        if (totalSpaces === 0) {
                            
                            availability = "red";
                            
                        }

                        $(".fc-day-top[data-date=" + date + "]").attr("data-availability", availability);

                        let background = "background-color: " + backgroundColour + ";"
                        $(".fc-day-top[data-date=" + date + "] .fc-day-number").attr("style", background);

                    });

                    
                }
            });

            self.calendar.render();
            
            if(window.lastViewedDate){
                
                self.calendar.gotoDate(window.lastViewedDate);

            } else {

                self.calendar.gotoDate(first_available);

            }


            $("body").on("click", ".fc-day-top", function () {

                self.dateClick($(this));

            });

            $("#console").attr("data-console-state", "choose-date");

            self.stateChanged();

        })

    }

    loadTimePicker(selectedDate) {

        let self = this;

        window.lastViewedDate = selectedDate;

        self.fetchDatesFromApi().then(function (dates) {

            let date = dates[selectedDate];

            
            if (date) {

                // Get the index in the date list for the selected date so we can work out next and previous buttons
                let index = Object.keys(dates).indexOf(selectedDate);

                let nextDate = Object.keys(dates)[index + 1];
                let previousDate = Object.keys(dates)[index-1];

                // Fetch slots template from API so logic can be handled
                // via handlebars

                $.get("/hummingbird/timeslot", { slots: JSON.stringify(date), "previous": previousDate, "next": nextDate }, function (template) {

                    $("#console-choose-time").html(template);

                    $("#console").attr("data-console-state", "choose-time");

                    self.stateChanged();

                })

                trackEvent("Booking", "Select a date", date[0].humanDate);

            }


        })


    }

    dateClick(clicked) {

        let self = this;

        self.showPlayerCounter(false);

        self.loadTimePicker(clicked.attr("data-date"));
    }

    validateVoucher(code) {

        let self = this;

        trackEvent("Booking", "Submit a gift code", code);

        return new Promise(function (resolve, reject) {

            let data = {
                value: code
            };

            //  If no game selected use current landing page
            // This only happens if a query string is used to get to the page with a
            // voucher prefilled

            let slug;

            if (self.session.huntTypeId) {

                slug = self.session.huntTypeSlug;

            } else {

                slug = self.landingPageSlug;

            }

            let url = "/api/london/" + slug + "/validate/voucher";

            $.post({
                url: url,
                data: JSON.stringify(data)
            }).done(function (response) {

                // Set voucher in session

                let message = response.message;

                $.post("/api/hummingbird/setvoucher/" + code, { message: message }, function () {

                    resolve();

                })

            }).fail(function (xhr, status, error) {

                // Remove voucher from session but do nothing else

                $.post("/api/hummingbird/clearvoucher").then(function () {

                    reject(xhr);

                })

            });

        });

    }

    loadSessionInfo() {

        let self = this;

        return new Promise(function (resolve) {

            $.ajax("/api/hummingbird/session", {
                cache: false,
                success: function (response) {

                    self.session = response;

                    resolve(self.session);

                }
            }

            )
        });

    }

    refreshConsole() {

        let self = this;

        self.refeshes += 1;

        $("body").removeClass("voucher-just-added");

        return new Promise(function (resolve) {

            self.loadSessionInfo().then(function () {

                let url = "/hummingbird";

                url += "?currency=" + self.currency;

                if (self.landingPage) {

                    url += "&landing=" + self.landingPage;

                    if (self.dismissedSwitchGame) {

                        url += "&dismissedSwitchGame=true"

                    }

                }

                let consoleRefresh = function () {

                    $.ajax(url, {
                        cache: false,
                        success: function (result) {

                            $("#hummingbird").html(result);

                            // Check if there are any messages to show

                            if ($("#console").attr("data-messages")) {

                                let messages = JSON.parse($("#console").attr("data-messages"));

                                messages.forEach(function (message) {

                                    self.popup(message[0], message[1]);

                                })

                            }

                            resolve();

                            // Toggle state change function to trigger related hooks

                            self.stateChanged();

                            // Check if body should scroll

                            self.checkBodyScroll();


                        }

                    }).catch(async function(){

                        // If hummingbird fails to load, possibly due to a session issue, reset the session and refresh the page

                        await self.resetConsole();

                        location.reload();

                    })
                }

                consoleRefresh();

            })

        })

    }

    dismissSwitchGame() {

        let self = this;

        self.dismissedSwitchGame = true;

        self.selectGame(self.session.huntTypeId);

    }

    selectGame(gameID) {

        if(!gameID){

            return;

        }

        let self = this;

        return new Promise(function (resolve) {

            let switchingGame = $("#console").attr("data-switch-game");

            $.post("/api/hummingbird/selectgame/" + gameID, function () {

                self.refreshConsole().then(function () {

                    if (switchingGame) {
                        self.checkVoucherinQuery();
                    }

                    // If switching to this new game on mobile, minimse the console

                    if (switchingGame && self.isMobile()) {

                        $("#console").attr("data-console-state", "add-players");

                        self.stateChanged();

                    }

                });

                

                resolve();

            });

        })

    }

    // Can't add players if no game selected, this is only possible
    // on the landing page for a game if nothing interacted with
    // so once interaction takes place, switch game

    checkPlayerChange() {

        let self = this;

        return new Promise(function (resolve) {

            if (self.session.huntTypeId) {

                resolve();

            } else {

                if (self.landingPage) {

                    self.selectGame(self.landingPage).then(resolve);

                }

            }

        })

    }

    getCurrentPlayerCount() {

        let count = $("[name=player-count]:visible").val();

        return parseInt(count);

    }

    setPlayers() {

        let self = this;

        return new Promise(function (resolve) {

            let count = self.getCurrentPlayerCount();

            self.checkPlayerChange().then(function () {

                $.post("/api/hummingbird/setplayers", { players: count }).done(function (data) {

                    self.refreshConsole().then(resolve);

                }).fail(function(){

                   self.resetExpiredConsole();

                });

            })

        })

    }

    setPlayersSoft() {

        let self = this;

        return new Promise(function (resolve) {

            let count = self.getCurrentPlayerCount();

            self.checkPlayerChange().then(function () {

                $.post("/api/hummingbird/setplayers", { players: count }).done(function (data) {

                    // Don't refresh the console

                }).fail(function(){

                   self.resetExpiredConsole();

                });

            })

        })

    }

    // To stop rapid clicks on the add button calling the server we wait

    playerChangeTimeout(norefresh) {

        let self = this;

        // Grey out the button

        $(".cost-and-buy-wrapper").addClass("disabled");
        $(".price-wrapper").css("opacity", 0);

        // Clear the timeout if already set

        if (self.playerChangeTimeoutTimer) {

            window.clearTimeout(self.playerChangeTimeoutTimer);

        }

        self.playerChangeTimeoutTimer = window.setTimeout(function () {

            if (norefresh) {

                self.setPlayersSoft().then(function () {

                    $(".cost-and-buy-wrapper").removeClass("disabled");
                    $(".price-wrapper").css("opacity", 1);
    
                })
            } else {

                self.setPlayers().then(function () {
                    
                    $(".cost-and-buy-wrapper").removeClass("disabled");
                    $(".price-wrapper").css("opacity", 1);
                    
                })
            }

        }, 500);

    }

    addPlayer(norefresh = false) {

        let self = this;

        let count = self.getCurrentPlayerCount() + 1;

        $("[name=player-count]").val(count);

        self.playerChangeTimeout(norefresh);

        trackEvent("Booking", "Clicked on + players", count);

    }

    removePlayer(norefresh = false) {

        let self = this;

        let count = self.getCurrentPlayerCount() - 1;

        $("[name=player-count]").val(count);

        self.playerChangeTimeout(norefresh);

        trackEvent("Booking", "Clicked on - players", count);

    }

    showPlayerCounter(show = true) {

        let self = this;

        if (show) {

            $("body").addClass("console-player-counter-active");

        } else {

            $("body").removeClass("console-player-counter-active");

        }

        trackEvent("Booking", "Reshow player counter", self.getCurrentPlayerCount());

    }

    toggleMinimised(minimised = null) {

        let self = this;

        if(window.hummingbirdTheme && window.hummingbirdTheme == "minimal-checkout") {
            minimised = false;
        }

        const thisConsole = $("#console_static") ? $("#console_static") : $("#console");

        // Default to toggling on and off
        if (minimised === null) {
            $("#console").toggleClass("console-minimised");
            $("#console_static").toggleClass("console-minimised");
            if (thisConsole.hasClass("console-minimised")) {
                $("body").addClass("console-minimised");
            } else {
                $("body").removeClass("console-minimised");
            }
        } else if (minimised === false) {
            $("#console").removeClass("console-minimised");
            $("#console_static").removeClass("console-minimised");
            $("body").removeClass("console-minimised");
        } else if (minimised === true) {
            $("#console").addClass("console-minimised");
            $("#console_static").addClass("console-minimised");
            $("body").addClass("console-minimised");
        }

        self.checkBodyScroll();


    }

    checkBodyScroll() {

        // Check if console is the same height as the window, if so disable scrolling the background

        let consoleHeight = $("#console").height();

        if (consoleHeight >= window.innerHeight - 50) {

            $("body").attr("no-scroll", "true");

        } else {

            $("body").removeAttr("no-scroll");

        }

    }

    fetchDatesFromApi() {

        let self = this;

        return new Promise(function (resolve, reject) {

            let url = "/api/london/" + self.session.huntTypeSlug + "/availability";

            // Add voucher code to availability lookup if set as
            // some vouchers can affect dates returned

            if (self.session.voucherCode) {

                url += "/" + self.session.voucherCode;

            }

            $.get(url, function (data) {

                let dates = {};

                data.forEach(function (date) {

                    if (!dates[date.date]) {

                        dates[date.date] = [];

                    }

                    dates[date.date].push(date);

                });

                resolve(dates);

            })

        })

    }

    selectSlot(slot) {

        let self = this;

        // First update slot session on server

        $.post("/api/hummingbird/selectslot", { slot: JSON.stringify(slot) }, function () {

            // Select the slot on screen

            self.refreshConsole();

        });

        trackEvent("Booking", "Select a time", slot.humanDate + " from " + slot.startFrom);

    }

    toggleVoucherScreen(event) {

        let self = this;

        if (event.target.checked) {

            $("#console").attr("data-console-state", "voucher");

            self.stateChanged();

        } else {

            // If there is a voucher set and the box is unticked

            if (self.session.voucherCode) {

                self.clearVoucher();

            }


        }

    }

    updateVoucher() {

        let self = this;

        $("#console").attr("data-console-state", "voucher");

        self.stateChanged();

        $(".voucher-wrap--complete").hide();

    }


    updateContact(report = false) {

        let self = this;

        return new Promise(function (resolve, reject) {

            let contact = {
                firstName: $("#firstName").val(),
                lastName: $("#lastName").val(),
                email: $("#email").val()
            }

            // Reset all field errors

            self.clearValidationMessages(".contact-field");

            $.post("/api/hummingbird/setcontact", { contact: contact }, function () {

                resolve();

            }).catch(function (xhr) {

                // Check if session has actually expired and we can't set contact (like if they've lost their cookies or hit back in another tab). This is triggered when no hunt type is set.
                if(xhr.status === 498){

                    self.resetExpiredConsole();

                    return;

                }

                let messages = JSON.parse(xhr.responseText);

                // Message is an array of error messages in the format
                // {field: ---, error: ----}

                trackEvent("Booking", "API validation", JSON.stringify(messages));

                let errors = [];

                messages.forEach(function (message) {

                    errors.push(message.field + "-" + message.error);

                    self.showMessageInField(message.field, message.error, "error", 10);

                })

                if (report) {

                    reject(errors);

                }

            })


        })


    }

    resetExpiredConsole() {

        let self = this;

        self.popup("Session expired", "This session has expired. Please refresh the page.", true, {}, function(){

            window.trackEvent("Errors", "Session expired pop up shown", "Session issue");


            self.resetConsole();

            self.checkVoucherinQuery();

        });

    }

    // If we've got to the details screen via a landing page
    // the update players widget is minimised

    checkAddPlayersButtonState() {

        let self = this;

        // Check if got to the details page via a non game landing page
        // If so we show the player counter expanded

        if (!self.session.progressedToDetailsViaLandingPage) {

            self.showPlayerCounter(true);

        } else {

            self.showPlayerCounter(false);

        }

    }

    addTicker() {

        $("#hummingbird").addClass("run-ticker");

    }

    removeTicker() {

        $("#hummingbird").removeClass("run-ticker");

    }

    goToBookerDetailsForm(payRedeemType) {

        let self = this;

        if(payRedeemType === "klarna"){

            window.trackEvent(
                "Booking",
                "Click 'KLARNA'",
                "Klarna selected"
              );

        }

        $.post("/api/hummingbird/progressed-to-booker-details/", { 
            klarna: payRedeemType === "klarna"
         }, function () {

            self.refreshConsole();

            if (payRedeemType === "card") {
                trackEvent("Booking", "Click 'Book with card'", "Card pay selected")
            }

            if(payRedeemType === "klarna"){

                trackEvent("Booking", "Click 'KLARNA'", "Klarna selected");

            }
            
        })
    }

    reserveDate(event) {

        let self = this;

        if (!self.session.partitionedDateId) {

            self.showCalendar();

        } else {

            self.clearSlot();

        }

        trackEvent("Booking", "Click reserve a date checkbox");

    }

    clearSlot() {

        let self = this;

        $.post("/api/hummingbird/clearslot", function () {

            self.refreshConsole();

            self.toggleMinimised(false);

        })

    }

    clearVoucher() {

        let self = this;

        $.post("/api/hummingbird/clearvoucher", function () {

            self.refreshConsole();

            self.toggleMinimised(false);

        })

    }

    buyKlarna(){

        let self = this;

        self.updateContact(false).then(function () {

            // Check if user has enough info to make a payment

            self.loadSessionInfo().then(function () {

                if(self.session.canBuy){

                    // Initialise stripe if not set
                    // TODO: This should be in the stripe js as a helper called by all methods

                    if(!window.stripe){

                        window.stripe = Stripe($("#console").attr("data-stripe-key"));

                    }

                    self.addTicker();

                    window.payWithKlarna(self.session.email);

                    return;

                } else {

                    if(!self.session.firstName){

                        self.showMessageInField("firstName", "This field is required", "error", 10);

                    };

                    if(!self.session.lastName){

                        self.showMessageInField("lastName", "This field is required", "error", 10);
                        
                    };

                    if(!self.session.email){

                        self.showMessageInField("email", "This field is required", "error", 10);
                        
                    };
                    
                }

            })

        });

    }

    buy(event, payment) {

        let self = this;
        let price = $.trim($(".price-wrapper").first().text());

        event.preventDefault();

        price = parseInt(price * 100);

        trackEvent("Booking", "Clicked pay now", "clicked", price);

        self.addTicker();

        let errors = [];

        $.each($("[data-required]"), function (index, element) {

            if (!element.value) {

                errors.push(element.name + " - " + "This field is required");

                self.showMessageInField(element.name, "This field is required", "error", 10);

            }

        })

        if (errors.length) {

            self.removeTicker();

            trackEvent("Booking", "API validation errors after clicking Pay", JSON.stringify(errors));

            return;

        }

        // Check contact details

        self.updateContact(true).then(function () {

            // Check if user has enough info to make a payment

            self.loadSessionInfo().then(function () {

                // In some cases the session will have completely timed out
                // in this case the console should refresh

                if (!self.session.huntTypeId) {

                    self.refreshConsole().then(function () {

                        self.popup("Check details", "Your session has timed out. Please try again");

                        self.removeTicker();

                    })

                    return;

                }

                // Something else may be wrong with the information entered.
                // This should be caught by inline validation but might not be.

                if (!self.session.canBuy) {

                    self.removeTicker();

                    return self.popup("Check details", "Please check your payment details");

                }

                // TODO:: Check this workflow

                if (!payment) {

                    $.post("/payment/finished", function (data) {
                        if (data.returnUrl) {
                          window.parent.location.href = data.returnUrl;
                        } else {
                          // Handle failure
                        }
                    });

                    return true;

                }

                window.payWithCard();

            })


        }).catch(function (errors) {

            self.removeTicker();

            trackEvent("Booking", "API validation errors after clicking Pay", JSON.stringify(errors));

        });

    }

    stateChanged() {

        let self = this;

        let state = $("#console").attr("data-console-state");

        if (self.fullScreenMobileScreens.includes(state)) {

            $("body").addClass("full-screen-console-mobile");

        } else {

            $("body").removeClass("full-screen-console-mobile");

        }

    }

    back() {

        let self = this;

        let state = $("#console").attr("data-console-state");

        if (state === "details" || state === "voucher" && !self.session.progressedToBookerDetails || state === "choose-date") {

            // If in dismissed switch game mode we go back to that dialog

            if (self.dismissedSwitchGame) {

                self.dismissedSwitchGame = false;

                self.refreshConsole().then(function () {

                    self.toggleMinimised(true);

                });

            } else {
                
                // Reset the console and go back to the home page

                // Hide Hummingbird so there's no flash

                $("#hummingbird").hide();

                self.resetConsole().then(function(){

                    location.reload();

                });

            }
        } else if (self.session.progressedToBookerDetails) {

            $.post("/api/hummingbird/cancel-progressed-to-booker-details/", {  }, function () {

                self.refreshConsole();
                
            })

        } else if (state === "choose-time") {

            self.showCalendar();

        } else {

            self.resetConsole();

        }

    }

    async closeCalendar() {

        let self = this;

        // If the game requires a date, this should be the same as going back to previous state 

        let hasVariablePricing = document.querySelectorAll("[data-variable-pricing]").length;

        if(hasVariablePricing){

            self.back();

        } else {

            self.refreshConsole();
        
        }


    }

    resetConsole() {

        return new Promise(function (resolve) {

            $.post("/api/hummingbird/reset", function () {

                resolve();

            })

        })

    }

}



window.popup = (heading, message, button = true, options = {}, callback = () => false) => {
        
    // If we already have a popup, don't show again.

    if(document.querySelector(".error-popup")){

        return;

    }

    let content = `<div class="error-popup">`;

    content += `<h2>${heading}</h2><p>${message}</p>`;

    if (button) {

        content += `<button onclick="$.fancybox.close()" class="button primary pointer">OK</button>`;


    }

    content += `</div>`;

    // Disable swiping popups

    options.touch = false;

    $.fancybox.open(content, options);

    callback();

}


window.showMessageInField = (field, message, type, extraoffset = 0) => {

    field = $("[name=" + field + "]");

    field.parent().addClass("has-message");

    if (message.length > 25) {
        field.parent().addClass("message-long");
    }

    field.parent().addClass(type);
    field.parent().find(".field-message").html(message);

    // Add required offset

    let messageHeight = field.parent().find(".field-message").height();

    let offset = -(messageHeight + 10 + extraoffset);

    field.parent().find(".field-message").css("top", offset + "px");

}


window.toggleSelectGameList = () => {

    $(".select-game-console-wrapper--toggleable").toggle();
    $(".buy-tickets-pill-wrapper").toggle();

    $("#console").toggleClass("select-game-open");
    $("#console_static").toggleClass("select-game-open");

}

window.backLite = () => {

    let state = $("[data-console-state]").attr("data-console-state");

    if (state === "gift-booker-details") {
        $("[data-console-state]").attr("data-console-state", "gift-details");
    }

}

window.goToLiteBookerDetailsForm = (method) => {

    if(method === "klarna"){

        $("#klarna-pay-button").show();
        $("#card-pay-button").hide();
        $("#card-element").hide();

    } else {

        $("#card-pay-button").show();
        $("#klarna-pay-button").hide();
        $("#card-element").show();

    }

    $("[data-console-state]").attr("data-console-state", "gift-booker-details");
    window.voucherPaymentCardSetup();
    
}


window.removePlayerLite = () => {
    let minPlayers = parseInt($("[data-min-players]").attr("data-min-players"));
    let playerCount = $(".player-count-current");
    let current = parseInt(playerCount.val());
    if(current > minPlayers){
        playerCount.val(current - 1);
    }
    window.updatePriceLite();
}

window.addPlayerLite = () => {
    let playerCount = $(".player-count-current");
    let current = parseInt(playerCount.val());
    playerCount.val(current + 1);
    window.updatePriceLite();
}

window.checkPlayerCountLite = () => {
    let minPlayers = parseInt($("[data-min-players]").attr("data-min-players"));
    let playerCount = $(".player-count-current");
    let current = parseInt(playerCount.val());
    if(current < minPlayers){
        playerCount.val(minPlayers);
    }
    window.updatePriceLite();
}

window.updatePriceLite = () => {
    let minPlayers = parseInt($("[data-min-players]").attr("data-min-players"));
    let playerCount = parseInt($(".player-count-current").val());
    
    if(playerCount > minPlayers) {
        $(".player-count-minus").removeClass("grey-out");
     } else {
        $(".player-count-minus").addClass("grey-out");
    }
}

$(() => {

    window.updatePriceLite();

    if(document.getElementById("voucher-price")){
        setVoucherPrice();
    }    

})


window.selectVoucherDesign = function(name, scroll = true){



    $("body").attr("data-voucher-design", name);

    // Change the dropdown (offscreen)

    document.getElementById("voucher-design").value = name;

    $(".gift-voucher-type").text(`Buy a ${name} gift voucher`);

    $("[data-voucher-card]").removeClass("active");

    $(`[data-voucher-card=${name}]`).addClass("active");

    if(scroll){

        $('html, body').animate({scrollTop: $(`[data-voucher-card=${name}]`).offset().top -100 }, 'slow');

    }


}

window.resetVouchertype = function(){

    $("[data-voucher-card]").removeClass("active");

    $("body").removeAttr("data-voucher-design");

}

$("body").on("change", "#voucher-design", function(){

    window.selectVoucherDesign(document.getElementById("voucher-design").value);

})

// Check if there is a query string voucher style

$(() => {

    window.setTimeout(() => {

        if(window.getURLParams()["voucher_design"]){
    
            window.selectVoucherDesign(window.getURLParams()["voucher_design"], false);
            
        };

    }, 500);

})
