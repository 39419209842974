window.payWithKlarna = async function (email) {

  const response = await fetch("/payment/intent/klarna", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  
  if(response.status > 299){
  
      // Payment request not made so session must not exist, refresh console
      return window.hummingbird.resetExpiredConsole();
  
  }
    
  intent = await response.json();;

  // Redirects away from the client
  const { error } = await stripe.confirmKlarnaPayment(intent.clientSecret, {
    payment_method: {
      billing_details: {
        email: email,
        address: {
          country: "GB",
        },
      },
    },
    return_url:
      location.protocol +
      "//" +
      location.host +
      "/payment/klarna/callback?product_type=game&origin=" + location.pathname,
  });
  if (error) {
    window.hummingbird.removeTicker();
  }
};

// Apple Pay / Google Pay buttons

window.stripeWallets = async function () {
  // First get payment info
  let response = await fetch("/payment/request");
  
  if(response.status > 299){

    // Payment request not made so session must not exist, refresh console
    return window.hummingbird.resetExpiredConsole();

  }
  
  response = await response.json();
  
  // Make a payment request button using data attributes in the console
  const paymentRequest = stripe.paymentRequest(response);
  const elements = stripe.elements();
  const prButton = elements.create("paymentRequestButton", {
    paymentRequest,
    style: {
      paymentRequestButton: {
        type: "default",
        theme: "light",
        height: "44px",
      },
    },
  });

  // Check if the user's browser supports Apple Pay / Google Pay etc via the web payments API
  const supportsWebPaymentAPI = await paymentRequest.canMakePayment();

  if (supportsWebPaymentAPI) {
    // Check which button shown and Google Event it

    if (supportsWebPaymentAPI.applePay) {
      window.trackEvent("Booking", "Show Apple Pay option", "Apple Pay shown");
    }

    if (supportsWebPaymentAPI.googlePay) {
      window.trackEvent(
        "Booking",
        "Show Google Pay option",
        "Google Pay shown"
      );
    }

    document.getElementById("payment-request-button--wallet").style.display =
      "block";

    prButton.mount("#payment-request-button--wallet");

    prButton.on("click", function (event) {
      if (supportsWebPaymentAPI.applePay) {
        window.trackEvent(
          "Booking",
          "Click 'Book with Apple Pay'",
          "Apple Pay selected"
        );
      }

      if (supportsWebPaymentAPI.googlePay) {
        window.trackEvent(
          "Booking",
          "Click 'Book with GPay'",
          "Google Pay selected"
        );
      }
    });
  } else {
    document.getElementById("payment-request-button--wallet").style.display =
      "none";
  }

  // Get the client secret for making the payment from the server

  paymentRequest.on("paymentmethod", async (ev) => {
    // Someone has already entered their google pay details

    // Call controller to get firstname lastname from name

    let formData = new FormData();
    formData.append("email", ev.payerEmail);
    formData.append("name", ev.payerName);

    await fetch("/api/wallet-set-contact", {
      body: formData,
      method: "post",
    }).then((response) => response.json());

    // Now we need to get the payment intent

    const clientSecret = await getClientSecret();

    // Confirm the PaymentIntent without handling potential next actions (yet).
    const { paymentIntent, error: confirmError } =
      await stripe.confirmCardPayment(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      );

    if (confirmError) {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      ev.complete("fail");
    } else {
      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.
      ev.complete("success");
      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow. If using an API version older than "2019-02-11"
      // instead check for: `paymentIntent.status === "requires_source_action"`.
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const { error } = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          // The payment failed -- ask your customer for a new payment method.
        } else {
          // Success

          success();
        }
      } else {
        // Success

        success();
      }
    }
  });
};

const getClientSecret = async function () {
  const response = await fetch("/payment/intent", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  
  if(response.status > 299){
  
      // Payment request not made so session must not exist, refresh console
      return window.hummingbird.resetExpiredConsole();
  
  }
    
  intent = await response.json();

  return intent.clientSecret;
};

window.stripeCard = async function () {
  const elements = stripe.elements();

  const style = {
    base: {
      color: "#32325d",
      lineHeight: "24px",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  };

  const card = elements.create("card", { style: style });

  card.mount("#card-element");

  card.on("change", function (event) {
    document.querySelector("#card-errors").textContent = event.error
      ? event.error.message
      : "";
  });

  window.payWithCard = async function () {
    // First get the client secret

    const clientSecret = await getClientSecret();

    stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: card,
        },
      })
      .then(function (result) {
        if (result.error) {
          document.querySelector("#card-errors").textContent = result.error
            ? result.error.message
            : "";
          hummingbird.removeTicker();
        } else {
          success();
        }
      });
  };
};

const success = async () => {
  $.post("/payment/finished", function (data) {
    window.parent.location.href = data.returnUrl;
  }).fail(function () {
    alert(
      "Something went wrong processing your payment. Please contact support."
    );
  });
};
